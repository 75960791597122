@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css?family=Poppins');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 147px;
}

body {
  overflow-x: hidden;
}

body.bg-img {
  background-image: url(./assets/images/bg-rings.svg);
  background-position: 131% -270px;
  background-repeat: no-repeat;
}

/* Header Section Starts */

header {
  padding: 10px;
  box-shadow: 3px 4px 20px 8px rgba(0, 51, 160, 0.15);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  backdrop-filter: blur(10px);
  z-index: 9;
}

header .container {
  height: 100%;
}

.bg-filter {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: -moz-linear-gradient(to right, #fff, #fff);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* filter: blur(5px); */
  z-index: -1;
}

.menu {
  margin-right: 10px;
}

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 40px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.menu-btn::after {
  border: none;
}

.menu-btn-burger {
  width: 100%;
  height: 1px;
  background-color: #0033a0;
  transition: all 0.3s ease-in-out;
}

.menu-btn-burger::before,
.menu-btn-burger::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #0033a0;
  transition: all 0.3s ease-in-out;
}

.menu-btn-burger::before {
  transform: translateY(-8px);
}

.menu-btn-burger::after {
  transform: translateY(8px);
  width: 70%;
}

.menu-btn.show .menu-btn-burger {
  transform: translateX(-30px);
  background-color: transparent;
}

.menu-btn.show .menu-btn-burger::before {
  transform: translate(30px, 0) rotate(45deg);
  width: 70%;
}

.menu-btn.show .menu-btn-burger::after {
  transform: translate(30px, 0) rotate(-45deg);
  width: 70%;
}

.menu-btn.dropdown-toggle::after {
  margin-left: 0;
}

.menu.dropdown .dropdown-menu {
  border: none;
  border-radius: 0;
  /* transition: .2s ease-in-out; */
  margin-top: 29px !important;
}

.menu.dropdown .dropdown-menu .dropdown-item:focus,
.menu.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #0033a0;
  color: #fff;
}

.brand {
  margin-left: 15px;
}

.brand div img {
  width: 114px;
}

.brand p {
  padding: 0px 10px 0px 16px;
  font-size: 20px;
  color: #0033a0;
  text-transform: uppercase;
}

.profile {
  cursor: pointer;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background-color: #0033a0; */
  margin-left: 10px;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  /* border-radius: 50%; */
}

.profile-name {
  color: #0033a0;
  font-size: 14px;
}

.language {
  margin-left: 60px;
}

.language a {
  font-size: 13px;
  color: #999999;
  margin-bottom: 0;
  padding: 3px 10px 5px 10px;
  text-decoration: none;
}

.language a.active {
  color: #0033a0;
  border: 1px solid #0033a0;
  border-radius: 14px;
  width: 90px;
}

/* Header Section Ends */

/* Main Wrapper/body Section Starts */

.wrapper {
  padding-top: 180px;
}

/* section.policy-hub{
    background-image: url(./assets/images/bg-rings.svg);
    background-position: 150% -300px;
    background-repeat: no-repeat;    
} */

.title {
  font-size: 33px;
  text-transform: capitalize;
  color: #0033a0;
  font-weight: bolder;
  /* font-family: 'Oswald', sans-serif; */
  font-weight: bold;
}

.title p {
  color: #96abd6;
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
}

.title p span {
  color: #0033a0;
}

.blue-flower{
  width: 55px;  
}

.blue-flower img, .white-flower img{
  width: 100%;
}

.white-flower{
  width: 50px;
}

.develop-banner {
  position: relative;
  height: 440px;
}

.develop-banner .container {
  width: 78%;
  float: right;
  max-width: 670px;
}

.develop-banner p {
  font-size: 22px;
  color: #fff;
  /* line-height: 36px;
  margin-top: 10px; */
  font-weight: bold;
}

.banner-img {
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0 80%);
  position: absolute;
  z-index: -1;
  background-color: #ccc;
  width: 100%;
  height: 510px;
  background-image: linear-gradient(
      to right,
      rgba(0, 51, 160, 0.7),
      rgba(0, 51, 160, 0.7)
    ),
    url(./assets/images/nischal-masand-0606MKTpyM8-unsplash.jpg);
  background-size: cover;
  /* background-position-x: -180px;
    background-position-y: -80px; */
}

.slider-wrapper {
  /* max-width: 670px;
  padding: 0 20px; */
  /* margin-top: 15px; */
  width: 100%;
}

.slider-wrapper ul li img {
  width: 100%;
  height: 100%;
}

.slider-wrapper .carousel-item {
  height: 550px;
}

.slider-wrapper .carousel-inner {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.about {
  padding-right: 20px;
}

.about p {
  font-size: 20px;
  color: #262f40;
  line-height: 38px;
  font-weight: 300;
}

.about-link {
  color: #0033a0;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

.about-link span {
  margin-left: 10px;
  padding: 2px 7px;
  border: 2px solid #0033a0;
  border-radius: 50%;
}

.about-link:hover {
  color: #0033a0;
}

.banner-link {
  color: #fff;
}

.banner-link:hover {
  color: #fff;
}

.banner-link span {
  border-color: #fff;
}

.news-event-wrapper {
  padding: 0 0px 0 25px;
}

/* .event-wrapper,.news-wrapper{
    padding: 0 15px;
} */

.news,
.event {
  max-height: 280px;
  overflow: auto;
  min-height: 170px;
}

.news::-webkit-scrollbar,
.event::-webkit-scrollbar {
  width: 3px;
}

.news-event-title {
  font-size: 25px;
  font-weight: bold;
}

.event-wrapper .news-event-title {
  color: #0033a0;
}

.news-wrapper .news-event-title {
  color: #0033a0;
}

.event-date,
.news-title {
  font-size: 14px;
  color: #0033a0;
  font-weight: bold;
}

.event-writup,
.news-writup {
  font-size: 12px;
  color: #9e9e9e;
  /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  width: 200px;
  line-height: 15px;
}

.event-writup a {
  color: #0033a0;
  text-decoration: none;
}

.event-icon span,
.news-icon p {
  font-size: 23px;
  color: #0033a0;
  border: 1px solid #0033a0;
  /* padding: 10px; */
  border-radius: 50%;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.news-icon p,
.news-title,
.news-icon span {
  color: #0033a0;
}

.news-sec {
  cursor: pointer;
}

.news-icon p {
  border: 1px solid #0033a0;
  line-height: 15px;
  padding-top: 9px;
  text-transform: capitalize;
}

#eventlandModal .news-icon p {
  width: 60px;
  height: 60px;
  line-height: 17px;
  font-size: 25px;
  padding-top: 11px;
  background-color: #fff;
}

#eventlandModal .news-writup {
  width: 100%;
  line-height: 20px;
  margin-top: 15px;
  color: #333;
}

.btn-bg-white {
  background-color: #fff;
}

.btn-new.event-pop-btn {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
  text-transform: none;
}

.month {
  font-size: 13px;
}

.migration-repo,
.director-gen {
  border: 1px solid #efeff9;
  border-radius: 20px;
  padding: 25px 30px;
}

.migration-repo {
  background-image: linear-gradient(to right, #fff, #fff, transparent),
    url(./assets/images/Policy-Repository_Banner_Original.jpg);
  background-size: contain;
  background-position-x: right;
  background-repeat: no-repeat;
  padding-left: 25px;
}

.director-gen {
  background-image: linear-gradient(to right, rgba(38, 47, 64, 1), transparent),
    url(./assets/images/HQ_ODG_Banner-2.jpg);
  background-size: cover;
  height: 204px;
  /* background-position-x: -120px; */
}

.migration-other-link p {
  margin-bottom: 0;
}

#search-migration {
  position: relative;
  width: 75%;
}

#search-migration button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #0033a0;
}

.migration-title {
  color: #0033a0;
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: 700;
  /* font-family: 'Oswald', sans-serif; */
}

.migration-other-link p a {
  font-size: 13px;
  color: #424242;
  text-transform: capitalize;
  text-decoration: none;
}

.migration-other-link p img {
  width: 40px;
  padding: 0 5px;
}

.migration-other-link p img {
  padding-left: 0;
}

.director-gen {
  cursor: pointer;
  height: 195px;
}

.director-gen p {
  font-size: 24px;
  font-weight: bolder;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.director-gen p a:hover {
  color: #fff;
}

.director-gen p span {
  font-size: 16px;
  font-weight: lighter;
}

.virtual-bg {
  background-color: #ffb81c;
}

.pdad-bg {
  background-color: #418fde;
}

.knowledge-bg {
  background-color: #5cb8b2;
}

.virtual-workspace,
.pdad,
.knowledge-sharing {
  min-height: 250px;
}

.virtual-workspace .container,
.pdad .container,
.knowledge-sharing .container {
  border-radius: 20px;
}

.virtual-workspace .container img,
.pdad .container img,
.knowledge-sharing .container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.rel-doc-name {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
}

.rel-doc-name:hover {
  color: #ffffff;
  text-decoration: none;
}

.sec-title {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}

.sub-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 100;
}

.sec-link-item {
  text-decoration: none;
  padding: 13px 20px;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  /* white-space: pre; */
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  margin-right: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.sec-link-item svg {
  margin-left: 5px;
}

.sec-link-2 .sec-link-item {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.sec-link-2 {
  margin-top: 2rem;
  max-height: 70px;
  overflow: hidden;
  transition: 0.5s;
}

.sec-link-2.active {
  max-height: 500px;
}

.sec-input-item {
  width: 100%;
  margin-bottom: 20px;
}

.sec-input-item span {
  padding: 15px 20px;
  background: #fff;
  border: none;
}

.sec-input-item span#virtual-addon1 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
}

.sec-input-item span#virtual-addon2 {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
  /* text-transform: uppercase; */
}

.sec-input-item input,
.sec-input-item input:focus {
  border: none;
  box-shadow: none;
}

.virtual-workspace .sec-link-item,
.virtual-workspace .sec-input-item span,
.virtual-workspace .sec-input-item input::placeholder,
.virtual-workspace .sec-input-item input {
  color: #ffb81c;
}

.pdad .sec-link-item,
.pdad .sec-input-item span,
.pdad .sec-input-item input::placeholder,
.pdad .sec-input-item input {
  color: #418fde;
}

.knowledge-sharing .sec-link-item,
.knowledge-sharing .sec-input-item span,
.knowledge-sharing .sec-input-item input::placeholder,
.knowledge-sharing .sec-input-item input {
  color: #5cb8b2;
}

.sec-link-item:hover {
  background-color: transparent;
  color: #fff;
  transition: 0.5s ease-in-out;
}

.sec-link-2 .sec-link-item {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-size: 12px;
  padding: 5px 10px;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 10px;
  margin-right: 10px;
}

.sec-link-2 .sec-link-item:hover {
  background: #fff;
  color: #418fde;
  transition: 0.5s ease-in-out;
}

/*.feed-wrapper {
  border: 1px solid rgb(0, 51, 160);
  padding: 35px;
  border-radius: 20px;
}*/

.feed-wrapper .feed-input,
.feed-wrapper .feed-input:focus {
  border: none;
  border-bottom: 1px solid #999;
  width: 100%;
  padding: 5px 0px;
  font-size: 18px;
  font-weight: lighter;
  padding-top: 5px;
  border-radius: 0;
  box-shadow: none;
}

.char-left {
  font-size: 14px;
  font-weight: lighter;
  color: #666;
}

.feed-btn {
  background: #0033a0;
  color: #fff;
  padding: 10px 12px;
  border-radius: 30px;
  float: right;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  /* box-sizing: border-box; */
  border: 2px solid #0033a0;
}

.feed-btn:hover {
  background-color: #fff;
  border: 2px solid #0033a0;
  color: #0033a0;
}

.feed-btn span {
  margin-left: 10px;
  /* padding: 1px 7px; */
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 20px;
  padding-left: 0px;
}

.feed-btn:hover span {
  border-color: #0033a0;
}

.feed-btn span svg {
  padding-left: 2px;
}

.feed-btn::after {
  clear: both;
}

#more {
  display: none;
  transition: 0.3s ease-in-out;
}

@-webkit-keyframes in {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    -webkit-transform: translateX(1);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes in {
  0% {
    transform: translateX(0);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    transform: translateX(1);
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes out {
  0% {
    -webkit-transform: translateX(1);
    opacity: 1;
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes out {
  0% {
    transform: translateX(1);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translateX(0);
    opacity: 0;
    visibility: hidden;
  }
}

#more.show {
  -webkit-animation: in 700ms ease both;
  animation: in 700ms ease both;
}

#more.hide {
  -webkit-animation: out 700ms ease both;
  animation: out 700ms ease both;
}

#myBtn {
  color: #fff;
  font-size: 13px;
  text-decoration: none;
  float: right;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
}

#myBtn:hover {
  color: #fff;
  /* text-decoration: underline; */
}

#myBtn:hover span svg {
  animation: arrowscroll 1s infinite;
}

.visuallyhidden {
  opacity: 0;
}

@keyframes arrowscroll {
  0% {
    opacity: 0;
    transform: translateY(-3px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(3px);
  }
}

.right-arrow-animation {
  animation: rightarrowscroll 1s infinite;
}

@keyframes rightarrowscroll {
  0% {
    opacity: 0;
    transform: translateX(-3px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(3px);
  }
}

.myBtn {
  animation: contentdisplay 1s;
}

@keyframes contentdisplay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#myBtn span {
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 1px;
  padding: 1px 4px;
  font-size: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
}

/* Community Practice page */

.content-header {
  /* background-color: #FFB81C; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  padding: 0 1px;
}

.content-header .row {
  width: 100%;
  height: 500px;
  margin: 0;
}

.content-header .row > div {
  overflow: hidden;
  height: 100%;
  padding: 0;
}

.content-title {
  /*background-color: #ffb81c;*/
  padding: 25px !important;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
}

.content-title h1,
.content-title p {
  color: #fff;
}

.content-title h1 {
  font-size: xx-large;
  font-weight: bolder;
}

.content-title p {
  font-size: 21px;
  font-weight: 100;
}

.content-header .row div img {
  width: 100%;
  object-fit: cover;
}

/* Moderator */

.moderators-header {
  background-color: #fff;
}

.moderators-header-wrapper {
  padding: 12px;
  border-right: none;
}

.m-moderators-list-inner {
  /* flex-direction: column; */
  /*display: none;*/
}

.l-moderators-list-inner {
  display: flex;
}

.member-count p,
.discussion-count p {
  text-transform: capitalize;
  font-size: 12px;
  /*color: #fff;*/
  display: flex;
  align-items: center;
}

.discussion-count svg {
  width: 18px;
}

.member-count p {
  padding-right: 12px;
}

.member-count p svg,
.member-count p span,
.discussion-count p svg,
.discussion-count p span {
  font-size: 16px;
  /* margin: 0 10px; */
  margin-right: 5px;
}

.member-count p span,
.discussion-count p span {
  padding-left: 12px;
}

.modeerator-member {
  padding: 10px 0;
}

.moderators-list {
  padding: 10px 15px;
  /*background-color: #f4f4f4;*/
}

.moderator-title {
  font-size: 11px;
  color: #33344a;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  margin-top: 0px;
}

.member-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #0033a0;
  text-align: center;
}

.member-profile img {
  width: 100%;
}

.member-profile span {
  color: white;
  font-size: 24px;
}
.member-name {
  font-size: 12px;
  font-weight: bolder;
  color: #464775;
  /* color: #0033a0; */
}

.member-name span {
  font-size: 10px;
  font-weight: 400;
}

.moderators-list-inner {
  /* background-color: #0033A0; */
  /*padding: 10px 15px;*/
  width: 100%;
}

.moderators-list-inner button {
  padding: 5px 30px;
  border-radius: 20px;
  border: 1px solid #0033a0;
  color: #0033a0;
  font-size: 12px;
  text-transform: uppercase;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  font-weight: bolder;
}

.moderators-list-inner button:hover {
  color: #fff;
  background-color: #0033a0;
}

.moderators-content-inner nav {
  display: flex;
  align-items: center;
  height: 66px;
  background-color: #0033a0;
  padding: 0 20px;
  border: 1px solid #fff;
}

.moderators-tab-content {
  padding: 10px 20px;
}

.moderators-content-inner nav .nav-tabs {
  border-bottom: none;
  height: 100%;
}

.moderators-content-inner nav .nav-tabs .nav-link {
  font-size: 12px;
  color: #fff;
  display: block;
  height: 100%;
  text-align: center;
  width: 95px;
}

.moderators-content-inner nav .nav-tabs .nav-link.active,
.moderators-content-inner nav .nav-tabs .nav-link:hover {
  color: #0033a0;
  border-color: transparent;
  border-top: 3px solid #ffb81c;
  background-color: #fff;
  border-radius: 0;
}

.moderators-content-inner nav .nav-tabs .nav-link.active svg path,
.moderators-content-inner nav .nav-tabs .nav-link:hover svg path {
  fill: #0033a0;
}

.moderators-content-inner nav .nav-tabs .nav-link svg {
  display: block;
  margin: auto;
  font-size: 21px;
  margin-bottom: 4px;
}

.moderators-tab-content {
  padding: 15px;
}

.mod-tab-title {
  background-color: #f4f4f4;
  padding: 10px 15px;
}

.mod-tab-title p {
  font-size: 14px;
  font-weight: bolder;
  text-transform: capitalize;
  color: #333;
}

.mod-tab-title p a {
  color: #0033a0;
}

.mod-tab-title p a:hover {
  color: #0033a0;
}

.mod-tab-body {
  margin-bottom: 20px;
  padding: 10px 16px;
}

.mod-tab-body p {
  font-size: 12px;
  color: #333;
  margin-top: 10px;
  max-height: 100px;
  overflow: hidden;
  margin-bottom: 5px;
  transition: all 1s;
  position: relative;
}

.mig-overview-content::after {
  content: "";
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to bottom, transparent, #fff);
  position: absolute;
  left: 0;
  bottom: -4px;
}

.mig-overview-content.active::after {
  height: 10px;
}

.mod-tab-body p.active {
  max-height: 500px;
  transition: all 1s;
}

.mod-tab-body p svg.fa-chevron-down.arrow-active {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.mod-tab-body li {
  font-size: 12px;
  list-style: none;
  padding: 7px 0;
  display: flex;
}

.mod-tab-body ul {
  padding-left: 3px;
}

.mod-tab-body li svg {
  /* content: '\f061';
  font-family: "Font Awesome 5 Free"; 
  font-weight: 900; */
  margin: 5px 15px 0 0;
  font-size: 10px;
  color: #0033a0;
}

.mod-tab-body #myBtn1 {
  float: none;
  color: #0033a0;
  font-weight: bolder;
  text-transform: capitalize;
  font-size: 12px;
  cursor: pointer;
  position: relative;
}

/* .mod-tab-body #myBtn1::before{
  content: '';
  width: 1000px;
  height: 20px;
  position: absolute;
  top: -30px;
  left: 0;
  background-image: linear-gradient(to bottom right, transparent, #fff);
} */

.upload-btn button,
.select-btn,
.modal-footer button,
.new-discussion-btn button,
.btn-new {
  border-color: #0033a0;
  color: #0033a0;
  font-size: 13px;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 7px 20px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

.upload-btn button:hover,
.new-discussion-btn button:hover,
.btn-new:hover {
  background-color: #0033a0;
  border-color: #0033a0;
  color: #fff;
}

.upload-btn button:focus,
.new-discussion-btn button:focus,
.btn-new:focus {
  box-shadow: none;
}

.new-discussion-btn button svg path {
  fill: #0033a0;
}

.new-discussion-btn button:hover svg path {
  fill: #fff;
}

.new-discussion-btn button svg {
  width: 17px;
}

.event-wrapper button svg {
  width: 15px;
}

.btn-new-primary {
  background-color: #0033a0;
  color: #fff !important;
}

.btn-new-primary:hover {
  background-color: transparent !important;
  color: #0033a0 !important;
}

.mod-btn {
  border-color: #0032A0;/* #ffff;*/
  color: #0033a0;
  background-color: #fff;
  font-size: 13px;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 7px 20px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

.mod-btn:hover {
  background-color: #5cb8b2; /* transparent;*/
  color: #fff;
}

.modal-footer button:hover,
.modal-body .select-btn:hover {
  background-color: #0033a0;
  border-color: #0033a0;
}

.modal-body input:disabled {
  background-color: #ffffff6b;
  border-color: #ddd;
}

.modal-body input {
  border-radius: 6px;
}

.upload-btn button svg,
.new-discussion-btn button svg {
  font-size: 16px;
  margin-right: 10px;
}

.content-tab-table thead {
  background-color: #f4f4f4;
  border-bottom-color: #464775;
  border-top: 1px solid #464775;
}

.content-tab-table thead th {
  color: #000;
  font-size: 13px;
  font-weight: bolder;
  padding: 15px 10px;
}

.content-tab-table tbody tr td:first-child svg {
  color: #0033a0;
  font-size: 15px;
}

.content-tab-table tbody tr td:first-child svg.fa-file-alt {
  font-size: 18px;
}

.develop-banner p.body-lm-text,
.about p.body-lm-text {
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
  color: #333;
  font-weight: 400;
  padding: 30px 0px;
}

.ptb-5 {
  padding: 5px;
}

.mnw-700 {
  min-width: 700px;
}

#LearnModal .modal-body {
  max-height: 500px;
  overflow: auto;
}

.content-tab-table tbody td {
  font-size: 13px;
  color: #333333;
  vertical-align: middle;
  height: 50px;
  padding-left: 11px;
}

.content-tab-table tbody tr:last-child {
  border-bottom-color: #464775;
}

.content-tab-table tbody td select.status-select {
  border: none;
  padding: 0;
  font-size: 13px;
  color: #333333;
  max-width: 190px;
  padding-right: 30px;
}

.content-tab-table tbody td select:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.content-tab-table tbody td a {
  font-size: 13px;
  margin-right: 30px;
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.content-tab-table tbody td:last-child {
  /* max-width: 120px; */
  min-width: 170px;
}

.modal-content {
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-header h5 {
  font-size: 17px;
  font-weight: bolder;
  text-transform: uppercase;
}

.modal-footer button,
.modal-body .select-btn {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  padding: 5px 20px;
  width: 112px;
}

.modal-body .form-label {
  font-size: 12px;
  color: #333333;
  font-weight: bolder;
}

.modal-body .form-content {
  font-size: 12px;
}

.modal-body .form-control,
.modal-body .form-select {
  font-size: 14px;
  padding: 9px 15px;
}

.modal-header {
  /* padding: 1rem 1rem 0.2rem 1rem; */
  padding: 20px 30px;
}

.modal-body {
  padding: 0 2rem;
  background: #f4f4f4;
}

.modal button.btn-close:hover {
  background-color: transparent;
  border-color: transparent;
}

.disscus-list-item {
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 6.5%);
  border-radius: 8px;
  border: 1px solid #eee;
}

.disscus-list-item.active {
  border-color: #999;
}

.discussion-header,
.discussion-body,
.discussion-footer {
  padding: 15px 18px;
}

.discussion-header {
  border-bottom: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.event-active .event-header {
  background-color: #ffb81c;
}

.event-list.event-active {
  border: 1px solid #ffb81c;
}

.discussion-body {
  min-height: 60px;
}

.discuss-desc {
  font-size: 12px;
}

.discussion-header p {
  font-size: 12px;
  color: #464775;
  font-weight: bolder;
}

.discussion-header p span {
  color: #0033a0;
}

.discuss-reply {
  font-size: 12px;
  color: #999999;
  font-weight: 100;
  text-decoration: none;
  border: 1px solid #999999;
  border-radius: 20px;
  padding: 5px 20px;
  padding-left: 15px;
}

.discuss-reply svg {
  color: #0033a0;
  margin-right: 7px;
}

.discuss-reply svg.teams-btn {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.discuss-reply:hover .reply-icon path {
  fill: #fff;
}

.discuss-reply:hover,
.discuss-reply:hover svg {
  background-color: #0033a0;
  border-color: #0033a0;
  color: #fff;
}

.event-header p {
  color: #000;
}

.discussion-body {
  padding: 8px 0;
}

.discussion-body .discuss-desc {
  padding: 0 18px;
}

.d-body-wrapper {
  background-color: #f9f9f9;
  padding: 10px 18px;
}

.d-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.d-desc-title {
  font-size: 12px;
  font-weight: 700;
  color: #0033a0;
  padding: 0 14px 0 0;
}

.d-desc-title span {
  margin-left: 10px;
  font-weight: 300;
  color: #333333;
}

.font-12 {
  font-size: 12px;
}

/* Cop Landing */

.cop-breadcrum {
  margin: -8px 5px 0px;
}

.cop-breadcrum p a,
.cop-breadcrum p a:hover {
  font-size: 12px;
  color: #333;
  text-decoration: none;
}

.cop-breadcrum p a:last-child {
  color: #0033a0;
}

.cop-breadcrum p a svg {
  margin: -1px 10px;
  font-size: 11px;
}

.cop-breadcrum p a:last-child {
  cursor: context-menu;
}

.folder-breadcrum {
  margin: 3px 5px 13px;
  padding: 10px 7px 0px;
  border-bottom: none;
}

.folder-breadcrum p a,
.folder-breadcrum p a:hover {
  font-size: 13px;
  color: #333;
  cursor: pointer;
}

.folder-breadcrum p a svg.fa-folder-open {
  margin-left: 0;
  margin-right: 6px;
  font-size: 14px;
  color: #0033a0;
}

.folder-breadcrum p a:last-child {
  padding: 5px 15px;
  background-color: #0033a0;
  color: #fff;
  border-radius: 20px;
}

.folder-breadcrum p a:last-child svg.fa-folder-open {
  color: #fff;
}

.folder-breadcrum p a.active {
  font-weight: 600;
  color: white;
}

.cop-header {
  /*background-color: #ffb81c;*/
  width: 100%;
  height: 100px;
  /*padding: 0 20px;*/
  /*border-top-left-radius: 10px;
  border-top-right-radius: 10px;*/
}

.cop-header > div {
  width: 100%;
}

.cop-header div h1 {
  color: #fff;
  /*text-transform: capitalize;*/
  font-weight: bolder;
  margin-bottom: 0;
}

.cop-header div select {
  max-width: 130px;
  border-radius: 20px;
  font-size: 13px;
  border: none;
}

.cop-header div select:focus {
  box-shadow: none;
}

.cop-body-wrapper {
  padding: 30px 0;
  border-bottom: 1px solid #ffb81c;
  gap: 35px;
}

.cop-col {
  width: 297px;
  height: 200px;
  background-color: #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /* margin-right: 30px;
  margin-bottom: 30px; */
  transition: 0.2s ease-in-out linear;
  /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);*/
}

.cop-col img {
  object-fit: cover;
  width: 100%;
}

.cop-col:hover img {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.cop-title {
  width: 100%;
  height: 50px;
  background-color: #ffb81c;
  position: absolute;
  bottom: 0;
}

.cop-col[disabled] .cop-title,
.cop-col[disabled] .cop-title > p {
  background-color: #999;
  color: #fff;
}

.cop-title > p {
  font-size: 14px;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  line-height: 17px;
  font-weight: bold;
}

.cop-footer-wrap {
  margin: 45px 0;
}

.cop-footer-wrap .page-link {
  border: none;
}

.cop-footer-wrap .page-link:focus,
.cop-footer-wrap .page-link:visited {
  box-shadow: none;
  background-color: transparent;
}

.cop-col:hover{
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.cop-footer-wrap .page-link.page {
  border-bottom: 2px solid transparent;
  transition: all 0.5s ease-in-out;
  color: #999;
  font-weight: bolder;
}

.cop-footer-wrap .page-link.page:hover,
.cop-footer-wrap .page-item.active .page-link.page {
  color: #000;
  /* font-weight: bolder; */
  background-color: transparent;
  border-bottom: 2px solid #ffb81c;
  box-sizing: border-box;
}

.cop-footer-wrap .page-link:hover {
  background-color: transparent;
}

.first-page svg,
.prve-page svg {
  transform: rotate(180deg);
}

.disabled .first-page svg path,
.disabled .prve-page svg path {
  fill: #999;
}

.cop-share-btn button {
  border-color: #0033a0;
  color: #0033a0;
  margin: 0 2px 9px 0;
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 14px;
}

.cop-share-btn button:hover {
  background-color: #0033a0;
  border-color: #0033a0;
}

.cop-share-btn button:focus {
  box-shadow: none;
}

.cop-share-btn button svg {
  margin: 0 6px 0 0;
}

td.action-icon svg path {
  color: #0033a0;
}

.event-sub-header p {
  margin-top: 4px;
  color: #000;
  font-weight: 600;
  font-size: 11px;
}
/* 
.event-dec{  
  font-weight: 300;
} */

.event-title {
  color: #333333;
  font-size: 12px;
  font-weight: bolder;
  margin-top: 10px;
  padding-left: 15px;
}

.event-reply a {
  color: #999999;
  border-color: #999999;
  border-radius: 20px;
  padding: 5px 20px;
  padding-left: 15px;
}

.event-reply a svg {
  margin-right: 7px;
  color: #0033a0;
}

.event-reply a:hover {
  background-color: #0033a0;
  border-color: #0033a0;
  color: #fff;
}

.event-reply a:hover svg {
  color: #fff;
}

.request-footer button {
  font-size: 14px;
  font-weight: bolder;
}

/* COP Landing Ends */

/* Request Workspace */

.workspace-form .form-control,
.workspace-form .form-select {
  padding: 0.8rem 0.75rem;
  transition: 0.5s;
  background-color: transparent;
  border-radius: 6px;
}

#testSelect1 {
  width: 100%;
}

.workspace-form .form-label {
  position: absolute;
  top: 50%;
  transition: 0.2s;
  transform: translateY(-50%);
  left: 10px;
  font-size: 15px;
  font-weight: 300;
  color: #999;
  padding: 10px;
  background: #fff;
  z-index: -1;
}

.workspace-form .form-control:focus,
.workspace-form .form-select:focus {
  box-shadow: none;
}

.workspace-form .form-control:focus,
.workspace-form .form-select:focus {
  border-color: #0032A0;/* #ffb81c;*/
}

.workspace-form input:focus + .form-label,
.workspace-form input:invalid + .form-label {
  top: -2px;
  font-size: 14px;
  color: #000;
  font-weight: bolder;
  padding: 5px 10px;
  z-index: 1;
}

.workspace-form input.valid + .form-label {
  top: -2px;
  font-size: 14px;
  color: #000;
  font-weight: bolder;
  padding: 5px 10px;
  z-index: 1;
}

.workspace-form select + .form-label,
.dropdown-check-list + .form-label {
  top: -2px;
  font-size: 14px;
  color: #000;
  font-weight: bolder;
  padding: 5px 10px;
  z-index: 1;
}

.workspace-form input + .form-label.obj-value {
  left: -10px;
}

.searchbtn {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* .multiselect-wrapper label{
  display: flex !important;  
  align-items: center;
} */

.event-wrapper .modal label {
  font-size: 12px;
}

.date-cal {
  position: relative;
}

.date-cal svg {
  /* content: '\f073';
  font-family: "Font Awesome 5 Free"; 
  font-weight: 900; */
  color: #0033a0;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-55%);
}

.add-link,
.add-link:hover {
  text-decoration: none;
  color: #0033a0;
  text-align: center;
  margin-left: 20px;
  font-weight: bolder;
  padding: 5px 15px;
  transition: 0.5s cubic-bezier(0.46, 0.82, 0.165, 1) 0.1s;
  width: 50px;
}

button.add-link {
  border: none;
  background: transparent;
}

.add-link:hover {
  background-color: #0033a0;
  color: #fff;
  border-radius: 5px;
}

.add-link .add-icon {
  font-size: 20px;
  line-height: 22px;
}

.add-link span {
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 1px;
  display: block;
}

.objective-sec {
  border-radius: 6px;
}

.del-link {
  line-height: 48px;
}

.create-btn {
  min-width: 150px;
  font-size: 12px !important;
  padding: 5px 20px !important;
}

/* .upload-btn .modal .modal-body input, .upload-btn .modal .modal-body select{
  max-width: 300px;
} */

#mod-modal .tab-pane {
  min-height: 100px;
}

/* Request Workspace End*/

#mod-modal .modal-body .nav {
  /* min-width: 280px;   */
  background: #0033a0;
}

/* #mod-modal .modal-body .tab-content{
  border-left: 1px solid #FFB81C;
} */

#mod-modal .modal-body .nav .nav-link {
  font-size: 14px;
  text-align: left;
  color: #fff;
  border-radius: 0;
  font-weight: bold;
}

#mod-modal .modal-body .nav .nav-link.active {
  background-color: #ffb81c;
  border-radius: 0;
  color: #fff;
  /* font-weight: bolder; */
}

#mod-modal .modal-body .nav .nav-link:hover {
  background-color: #ffb81c;
}

.mod-wrpar {
  padding: 30px;
}

#mod-modal .modal-header h5 {
  font-size: 16px;
  font-weight: bolder;
  text-transform: uppercase;
}

#mod-modal .modal-dialog {
  max-width: 1000px;
}

.mod-title {
  font-size: 20px;
  font-weight: 300;
}

.mod-table thead th {
  border: none;
  font-size: 12px;
}

.mod-table tbody td {
  font-size: 12px;
  vertical-align: middle;
}

.mod-table thead th .form-check .form-check-input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 0;
}

.mod-table tbody td .form-check .form-check-input {
  /* margin-top: 8px; */
  width: 18px;
  height: 18px;
}

.mod-table tbody td,
.mod-table thead th {
  padding: 12px 15px;
  vertical-align: middle;
}

.mod-table thead tr {
  background-color: #fff;
}

.mod-table tbody tr {
  border-bottom-color: #fff;
}

.mod-table tbody tr:last-child {
  border-bottom-color: #ffb81c;
}

.mod-table .cop-footer .cop-footer-wrap {
  margin: 10px 0;
}

.form-check-input:checked {
  background-color: #0033a0;
  border-color: #0033a0;
}

.form-check-input:focus,
button,
a.btn {
  box-shadow: none !important;
}

.page-item.disabled .page-link,
.page-item .page-link {
  background-color: transparent;
}

.nav-border {
  border-bottom: 1px solid #ffb81c;
  padding-bottom: 2px;
}

.workspace-form input {
  height: 60px;
  border-radius: 0.3rem;
}

.workspace-form .form-row div {
  margin-bottom: 4px;
  margin-top: 20px;
}

.add-edit-table table th {
  vertical-align: middle;
}

/* .mod-table table th.header-sticky{
  position: sticky;
  top: 0;
} */

.add-edit-table table th button {
  font-size: 10px;
  float: right;
  padding: 5px 10px;
}

.add-edit-table table td {
  vertical-align: middle;
}

.add-edit-table table td .form-check {
  margin-bottom: 0;
  min-height: auto;
}

.add-edit-table table td .form-check .form-check-input {
  margin-top: 3px;
}

.tableadd-btn {
  font-size: 11px;
  font-weight: bolder;
  padding: 4px 22px;
}

.table-add-search input {
  border: none;
  font-size: 12px !important;
  padding: 12px 13px;
  border-radius: 0;
  padding: 16px 15px !important;
}

.table-add-search {
  position: relative;
}

.table-add-search svg {
  /* content: '\f002';
  font-family: "Font Awesome 5 Free";
  font-weight: 900; */
  font-size: 14px;
  margin-left: 14px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #0033a0;
}

.pri-pub-wrap {
  width: 100%;
  background-color: #fff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.pri-pub-label {
  font-size: 14px;
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
}

/* Slider */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #0033a0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: #0033a0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider1 {
  background-color: transparent;
}

input:focus + .slider1 {
  box-shadow: 0 0 1px #0033a0;
}

input:checked + .slider1:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(34px);
}

/* Rounded sliders */
.slider1.round {
  border-radius: 34px;
}

.slider1.round:before {
  border-radius: 50%;
}

/* Slider End */

/* Moderator End */

/* Find Work Space */
.work-table-th {
  border-top: 1px solid #33344a !important;
  border-bottom: 1px solid #33344a !important;
}

.work-table-th th {
  border-top: 1px solid #33344a !important;
  border-bottom: 1px solid #33344a !important;
}

.work-table-th th {
  /* border-top: 1px solid #33344A;
  border-bottom: 1px solid #33344A !important; */
  padding: 15px 7px;
}

.work-table-th th label {
  font-size: 16px;
  color: #0032A0;/* #ffb81c;*/
  min-width: 190px;
  /* padding-top: 8px; */
  text-transform: uppercase;
  cursor: pointer;
}

.find-work-table .table {
  border-color: #fff;
}

.work-table-th th label svg {
  /* content: '\f002';
  font-family: "Font Awesome 5 Free";
  font-weight: 900; */
  margin-left: 14px;
}

.work-table-th th input {
  width: 80%;
  font-size: 16px;
  font-weight: bolder;
  color: #333333;
  outline: none;
}

.work-table-th th input:focus-visible {
  outline: none;
}

.work-table-td tr td {
  background-color: #fcfcfc;
  padding: 15px 10px;
}

.work-table-td tr td:first-child {
  width: 170px;
}

.form-select:focus {
  box-shadow: none;
}

.work-table-td tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}

.work-table-td td,
.work-table-td td select {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}

.work-table-td td select:focus-visible {
  outline: none;
}

.work-table-td {
  border-top: 1px solid #33344a;
}

.result-work-table table thead tr {
  background-color: #0032A0; /* #ffb81c;*/
}

.result-work-table table thead th:first-child {
  min-width: 290px;
}

.result-work-table .table > :not(caption) > * > * {
  border: none;
}

.result-work-table table tbody tr:last-child {
  border-bottom: 2px solid #CCCCCC; /* #ffb81c;*/
}

.result-work-table .table > :not(:last-child) > :last-child > * {
  border: none;
  vertical-align: middle;
  padding: 15px 7px;
  padding-left: 18px;
}

.result-work-table table thead tr th,
.table-th-select select {
  font-size: 14px;
  color: white; /* #333333;*/
  font-weight: bolder;
  text-transform: uppercase;
}

.result-work-table table thead tr th:last-child {
  padding-left: 5px !important;
}

.result-work-table table td {
  font-size: 13px;
  color: #333333;
  padding: 19px 17px;
  vertical-align: middle;
}

.table-th-select select {
  background-color: transparent;
  border: none;
  font-weight: bold;
  /* padding-bottom: 0px;
  padding-left: 0; */
  min-width: 115px;
}

.result-work-table .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #EDF6FF; /* #fff8e7;*/
  --bs-table-accent-bg: transparent;
}

.status svg {
  /* content: '\f111';
  font-family: "Font Awesome 5 Free";
  font-weight: 900; */
  margin-right: 10px;
  font-size: 10px;
  color: #ffb81c;
}

.status.inactive svg {
  color: #999999;
}
/* Find Work space end */

/* #myBtn.toggle-icon span svg.fa-chevron-down{
  transform: rotate(180deg);
  transition: 0.5s;
} */

#myBtn.toggle-icon:hover span svg.fa-chevron-up {
  animation: uparrow 1s infinite;
}

@keyframes uparrow {
  0% {
    opacity: 0;
    transform: translateY(3px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-3px);
  }
}

/* Community Practice page End*/

/* Admin */

/* Menu Toggle */

.toggle-menu {
  margin-left: 20px;
  cursor: pointer;
  display: none;
}

.toggle-menu span {
  width: 20px;
  height: 2px;
  display: block;
  background-color: #0033a0;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.toggle-menu span::before,
.toggle-menu span::after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #0033a0;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

.toggle-menu span::before {
  top: -7px;
}

.toggle-menu span::after {
  bottom: -7px;
}

.toggle-menu.active span {
  transform: translateX(-20px);
  background-color: transparent;
}

.toggle-menu.active span::before {
  transform: translateX(20px) rotate(45deg);
  top: -0;
}

.toggle-menu.active span::after {
  transform: translateX(20px) rotate(-45deg);
  bottom: 0;
}

/* Menu TOggle End */

.desc-title {
  max-width: 300px;
}

.events-table-title {
  max-width: 200px;
}

.amin-wrapper {
  padding-top: 75px;
  /* display: flex; */
}

.admin header .brand {
  margin-left: 0;
  padding: 9px 15px;
  background: #0033a0;
}

.sidebar {
  width: 246px;
  background-color: #fff;
  height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;
  /* padding: 15px 20px; */
  box-shadow: 0 0px 9px 2px rgb(0 0 0 / 20%);
}

.sidebar .nav-link {
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
  text-transform: uppercase;
}

.sidebar .nav-link.active {
  background-color: #0033a0;
}

.admin header {
  height: 75px;
  padding: 0;
}

.admin .sidebar .accordion-item {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.admin .sidebar .accordion-item .accordion-button {
  padding: 1.3rem 0 1.3rem 0;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  text-transform: uppercase;
}

.admin .sidebar .accordion-item .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #0033a0;
  /* font-weight: bolder; */
}

.admin .sidebar .accordion-item .accordion-body {
  padding: 1rem 0.25rem;
}

.admin .sidebar .accordion-item .accordion-button::after {
  width: 0.75rem;
  height: 0.75rem;
  background-size: 0.75rem;
}

.admin-title h5 {
  color: #333;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-transform: uppercase;
}

.sidbar-active,
.sidbar-active h5 {
  color: #0033a0 !important;
}

.sidbar-active::before {
  content: "";
  width: 10px;
  height: 20px;
  background-color: #0033a0;
  position: absolute;
  top: 0px;
  left: -30px;
}

a.sidbar-active::before {
  left: -34px;
}

.active-color {
  color: #0033a0;
}

.copywrite {
  color: #fff;
  font-size: 10px;
  flex: 1;
  text-align: center;
}

#admin-date {
  padding-right: 30px;
}

.admin-date {
  width: 150px;
}

.admin footer {
  padding: 15px 30px;
  height: 56px;
}

/*.admin footer .logo-brand {
  width: 130px;
  padding: 0;
}*/

.admin footer .logo-brand {
  width: 116px;
  height: 40px;
  padding: 0;
}

.admin footer .logo-brand img {
  width: 100%;
}

.sidewrapper {
  overflow: auto;
  height: 100%;
  padding: 0 30px;
}

.admin-foot h5 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.right-content {
  background: #f4f4f4;
  height: calc(100vh - 75px);
  overflow: auto;
}

.admin-content {
  min-height: calc(100vh - 131px);
  padding: 20px 30px;
}

.admin-link {
  color: #333333;
  font-size: 14px;
  padding: 0px 0 20px 0;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 500;
}

.admin-link:hover {
  color: #0033a0;
}

.admin-btn {
  background-color: #fff;
  border: 1px solid #d9e0f1;
  border-radius: 30px;
  margin: 0 7px;
  padding: 5px 30px;
  font-size: 14px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  text-transform: uppercase;
}

.admin-sub-btn {
  font-size: 10px;
  padding: 3px 18px;
  text-transform: uppercase;
  box-shadow: none !important;
}

.admin-btn:hover {
  background-color: #0033a0;
  color: #fff;
}

.admin-btn:disabled {
  background-color: #f4f4f4;
  color: #cccccc;
}

.admin-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.admin-btn-wrapper {
  position: absolute;
  right: 10px;
  bottom: -15px;
}

.admin-card p {
  color: #333333;
  font-size: 14px;
  margin-bottom: 5px;
}

.admin-card ol li {
  color: #333333;
  font-size: 14px;
  margin-bottom: 5px;
}

.admin-body-title {
  color: #333333;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.main-admin-title h1 {
  color: #333333;
  font-size: 16px;
  font-weight: bolder;
  padding: 25px 0 20px 0;
  border-bottom: 1px solid #0033a0;
  text-transform: uppercase;
  letter-spacing: 3.2px;
}

.main-admin-title h1 span {
  font-weight: 300;
}

.main-title p {
  color: #333333;
  font-size: 12px;
  font-weight: bolder;
}

.main-title p span {
  color: #999999;
  font-weight: 400;
}

.mb-70 {
  margin-bottom: 70px;
}

.admin-img-list {
  gap: 20px;
  flex-wrap: wrap;
}

.img-col {
  min-width: 200px;
  height: 150px;
  border: 1px solid #d9e0f1;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  /* max-width: 250px; */
}

.img-col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-del {
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-image: linear-gradient(45deg, transparent, rgba(0, 0, 0, 0.6));
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}

.img-del svg {
  color: #fff;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.admin-add-img {
  color: #d9e0f1;
  font-size: 35px;
}

.img-col:hover .img-del {
  /* top: 0; */
  opacity: 1;
  visibility: visible;
}

.event-table .table {
  border-color: #eeeeee;
}

.event-table .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #ccc;
}

.event-table tr th {
  font-size: 12px;
  color: #333333;
  font-weight: bolder;
  text-transform: uppercase;
}

.event-table tr th div {
  margin-top: 10px;
}

.event-table tr th input {
  font-size: 12px;
  padding: 10px;
}

.event-table tbody tr:last-child {
  border-bottom-color: #ccc;
}

.event-table tr td {
  font-size: 12px;
  color: #333333;
  vertical-align: middle;
}

.event-table table th {
  position: relative;
}

.event-table table th .alert-danger {
  position: absolute;
  bottom: 0;
}

.add-admin-detail,
.save-admin-detail {
  font-size: 10px;
  color: #333333;
  padding: 10px 7px;
  border: 1px solid #d9e0f1;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.add-admin-detail svg,
.save-admin-detail svg {
  font-size: 20px;
  color: #d9e0f1;
  margin-right: 6px;
}

.event-action {
  max-width: 70px;
}

.admin-action svg {
  font-size: 20px;
  color: #d9e0f1;
}

.admin-detail td a {
  color: #0000ff;
  text-decoration: none;
}

.detail-pagination .page-item .page-link {
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  color: #666666;
  border-radius: 50%;
  font-size: 14px;
}

.detail-pagination .page-item[disabled] .page-link {
  background-color: #f4f4f4;
  color: #cccccc;
}

.detail-pagination .page-item .page-link.main-pagei {
  color: #cccccc;
}

.detail-pagination .page-item .page-link.active {
  font-weight: bolder;
  color: #000;
}

.page-item:first-child .page-link.adjacent-link {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.page-item:last-child .page-link.adjacent-link {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.adjacent-link {
  padding: 3px 12px;
  border-radius: 30px !important;
}

.detail-pagination .page-item .page-link.adjacent-link {
  font-size: 10px;
}

.detail-pagination .page-item {
  margin: 0 5px;
}

.admin-cal {
  top: 50%;
  transform: translateY(-55%);
  right: 10px;
  color: #d9e0f1;
  font-size: 18px;
}

.about-policy-desc-min {
  /* max-height: 200px; */
  overflow: visible;
}

/* Temperory */
.workspace-form .form-row .dropdown-check-list {
  display: inline-block;
  width: 100%;
  position: relative;
}

.workspace-form .form-select {
  padding: 1rem 0.75rem;
}

.thematic-select {
  margin-bottom: 30px !important;
}

.select-dropdown {
  margin-bottom: 60px !important;
}

.workspace-form .form-row .dropdown-check-list.visible {
  margin-bottom: 0 !important;
}

.dropdown-check-list .anchor {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  padding: 1rem 0.75rem;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 6px;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 1.5px solid black;
  border-top: 1.5px solid black;
  padding: 3.2px;
  right: 15px;
  top: 40%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  margin-top: 50px;
  max-height: 280px;
  overflow: auto;
  border-radius: 6px;
}

.dropdown-check-list ul.items li {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.dropdown-check-list ul.items li:last-child {
  padding-bottom: 20px;
}

.dropdown-check-list ul.items li input {
  height: 18px;
  width: 18px;
}

.dropdown-check-list.visible .anchor {
  color: #333;
  border-bottom: 0;
}

.dropdown-check-list.visible .items {
  display: block;
}
/* Temperory END */

.main-pagei {
  border: none;
}

.main-pagei.active {
  border: 1px solid #dee2e6;
}

.admin-event-edit {
  width: 19px;
  height: 19px;
  margin-top: -8px;
}

.thematic-add-cardbody {
  border-radius: 6px;
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  padding: 1.25rem;
}

.clr-666 {
  color: #666;
}

.thematic-area {
  padding: 16px 20px;
  border-bottom: 1px solid #fff;
  transition: all 0.2s ease-in-out;
}

.thematic-area:first-child {
  border-top: 1px solid #fff;
}

.thematic-area:hover {
  background-color: #fff;
  /* border-radius: 6px; */
  border-color: transparent;
}

.thematic-action-btn {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.thematic-area:hover .thematic-action-btn {
  visibility: visible !important;
  opacity: 1;
}

.thematic-title {
  font-size: 14px;
  color: #333333;
}

.banner-img-wrapper {
  width: 176px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-right: 40px;
}

.banner-img-wrapper img {
  width: 100%;
  object-fit: cover;
}

.banner-img-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 35px;
  color: #fff;
}

.banner-img-wrapper + p {
  font-size: 12px;
  color: #999999;
}

.hidden {
  display: none;
}

/* Authorize */

.authorize-list .accordion-item {
  margin-bottom: 2px;
  border-radius: 6px;
  border: none;
}

.authorize-list .accordion-item .action button {
  box-shadow: none !important;
  text-transform: uppercase;
}

.authorize-list .accordion-item div p {
  margin-right: 40px;
  font-size: 12px;
  font-weight: 600;
}

.authorize-list .accordion-item .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #333;
}

.authorize-list .accordion-item .accordion-button::after,
.workspace-item .accordion-button::after {
  display: none;
}

.auth-item .card {
  border: none;
  margin-bottom: 10px;
}

.auth-item .accordion-body {
  padding: 0 20px;
}

.authorize-list .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.reject-pop .card-body {
  padding: 35px 20px;
}

.reject-sec {
  border: 1px solid #999999;
  border-radius: 6px;
}

.reject-head {
  padding: 12px 20px;
  background-color: #fbfbfb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.reject-head p {
  color: #333333;
  font-size: 12px !important;
  font-weight: bolder !important;
}

.reject-head p svg {
  font-size: 18px;
}

.reject-body {
  padding: 20px;
}

.reject-body textarea {
  font-size: 14px;
  color: #333333;
}

/* .worspace-wrap{
  padding: 20px;
} */

.workspace-col {
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}

.workspace-col:last-child {
  border: none;
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.workspace-col p {
  margin-bottom: 0;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.workspace-col p.workpace-title {
  margin-bottom: 10px;
  font-weight: bolder !important;
}

.workspace-body p {
  margin-right: 20px !important;
}

/* Authorize END */

/* Workspace */

.workspace-item {
  margin-bottom: 2px;
  border-radius: 6px;
  border: none;
}

.workspace-item[disabled] .accordion-header .accordion-button {
  opacity: 0.5;
  background-color: #f4f4f4;
  cursor: not-allowed;
}

.workspace-item .collapsed .worspace-action,
.workspace-item[disabled] .accordion-header .accordion-button .worspace-action {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.workspace-item:hover .worspace-action {
  visibility: visible;
  opacity: 1;
}

.workspace-item .worspace-action .admin-btn {
  box-shadow: none !important;
}

.workspace-item .accordion-button {
  padding: 2px;
  box-shadow: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.workspace-item .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: 0 5px 12px rgb(0 0 0 / 10%) !important;
  height: 100%;
}

.workspace-item .accordion-body {
  margin-bottom: 10px;
}

.work-img-wrap {
  width: 120px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 20px;
}

.work-img-wrap img {
  width: 100%;
  object-fit: cover;
}

.workpacelist-title {
  color: #333333;
  font-size: 12px;
}

.worspace-wrap .nav-tabs .nav-link {
  max-width: 160px;
  text-align: left;
  font-size: 12px;
  color: #999999;
  height: 100%;
  border: none;
  font-weight: 600;
}

.worspace-wrap .nav-tabs .nav-link.active,
.worspace-wrap .nav-tabs .nav-link:hover {
  background-color: #0033a0;
  color: #fff;
  border-radius: 0;
}

.worspace-wrap .nav-tabs {
  background-color: #f4f4f4;
  border-bottom: none;
  margin-bottom: 1px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
}

.worspace-wrap > .btn-wrap {
  border-bottom: 1px solid #0033a0;
}

.workspacelist-body {
  padding: 20px;
}

.workspacelist-title {
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 20px;
  color: #333333;
}

.cover-img p {
  font-size: 12px;
  color: #999999;
}

.cover-img-wrap {
  border: 1px solid #d9e0f1;
  width: 150px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.cover-img-wrap img {
  width: 100%;
  object-fit: cover;
}

.cover-img-wrap svg {
  font-size: 34px;
  color: #d9e0f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.workspacelist-body-content table td,
.workspacelist-body-content table th {
  font-size: 12px;
}

.workspacelist-body-content table thead tr {
  border-top: 1px solid #999999;
  background-color: #fbfbfb;
}

.workspacelist-body-content table th {
  font-weight: bolder;
  border-bottom-color: #999999 !important;
}

.workspacelist-body-content table td {
  border-bottom-color: #ccc;
  vertical-align: middle;
  padding: 10px;
}

.workspacelist-body-content table tr:last-child td {
  border-bottom-color: #0033a0;
}

.workspacelist-body-content table tr td .form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.workspacelist-body-content table tr th .form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-right: 8px;
}

.workspacelist-body-content table tr th .form-check-label {
  margin-top: 4px;
}

.workspacelist-body-content table tr td .action button {
  font-size: 10px;
  color: #666666;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 5px 14px;
}

.workspacelist-body-content table tr td .action button svg {
  margin-right: 5px;
}

.workspacelist-body-content table tr td .action button:hover {
  color: #fff;
}

.moderator-modal .modal-content {
  border-radius: 6px;
}

.moderator-modal .modal-dialog .modal-header {
  background-color: #fbfbfb;
  padding: 12px 20px;
}

.moderator-modal .modal-dialog .modal-header h5 {
  font-size: 12px;
}

.moderator-modal .modal-dialog .modal-body {
  background-color: #fff;
  padding: 20px;
}

.dec-action {
  color: #666666;
  right: 10px;
}

.workspacelist-search {
  font-size: 12px;
  padding: 12px 13px;
}

.workspacelist-search + svg {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #666;
}

.add-remove-member .action button {
  padding: 5px 10px;
  font-size: 10px;
  color: #666666;
}

.add-remove-member .action button:hover {
  color: #fff;
}

.add-remove-member .action button svg {
  margin-right: 5px;
}

.form-radio label {
  font-size: 12px;
}

.form-radio .form-check-input {
  margin-top: 0.35em;
}

.form-radio .form-check-input:checked[type="radio"] {
  background-color: #666;
  border-color: #d9e0f1;
}

.form-radio .form-check-input:checked[type="radio"] + label {
  font-weight: bolder;
}

/* Worspace END */

/* Admin Ends*/

/* Learn More */

.learn-more-img-wrapper {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  margin: 32px 0;
}

.learn-more-img-wrapper img {
  width: 100%;
  object-fit: cover;
}

.learn-more-detail p,
.learn-more-detail ul li {
  color: #262f40;
  font-size: 20px;
  line-height: 34px;
  font-weight: 300;
}

.learn-more-detail p {
  margin-bottom: 35px;
}

.learn-more-detail ul li {
  list-style: none;
  padding: 17.5px 0;
  position: relative;
  padding-left: 40px;
}

.learn-more-detail ul li::before {
  content: "\f061";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #0033a0;
  position: absolute;
  left: 0;
}

/* Learn More END */

/*/ PDAD Sharing Starts /*/

.main-pdad-doc {
  font-size: 14px;
  color: #333333;
  font-weight: bolder;
}

.admin-pdad .accordion-button {
  padding: 15px 20px;
  border-radius: 6px;
}

.admin-pdad .accordion-item:last-of-type .accordion-button.collapsed,
.admin-pdad .accordion-item:first-of-type .accordion-button {
  border-radius: 6px;
}

.admin-pdad .accordion-button .workpacelist-title {
  font-weight: 700;
}

.admin-pdad .workspace-item {
  margin-bottom: 10px;
}

.admin-pdad .workspace-item:hover .accordion-button .worspace-action {
  display: none !important;
}

.admin-pdad
  .workspace-item:hover
  .accordion-button:not(.collapsed)
  .worspace-action {
  display: block !important;
}

.admin-pdad
  .workspace-item:hover
  .accordion-button:not(.collapsed)
  .worspace-action
  button:first-child {
  margin-right: 3px;
}

.admin-pdad .workspace-item:hover .accordion-button .worspace-action {
  display: none;
}

.pdad-action .thematic-edit {
  display: none;
}

.admin-pdad
  .workspace-item:hover
  .accordion-button:not(.collapsed)
  .thematic-edit {
  display: none;
}

.admin-pdad .workspace-item:hover .accordion-button .thematic-edit {
  display: block;
  margin: 5px 0;
}

.pdad-admin-cat {
  border-bottom: 1px solid #d9e0f1;
  padding: 10px 0 20px;
}

.pdad-admin-select {
  border-bottom: 1px solid #d9e0f1;
  padding: 20px 0;
}

.pdad-admin-select .row {
  margin-bottom: 20px;
}

.pdad-admin-cat p,
.pdad-admin-select p {
  font-size: 12px;
  font-weight: 700;
}

.pdad-admin-cat select,
.pdad-admin-select select {
  font-size: 12px;
  border: none;
  padding: 0;
  min-width: none;
  width: auto;
}

.pdad-admin-form label,
.pdad-admin-country label {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}

.pdad-admin-form {
  /*border-bottom: 1px solid #d9e0f1;*/
  padding: 0px 0 20px;
}

.pdad-admin-country {
  padding: 20px 0;
}

.pdad-admin-country select {
  width: auto;
  font-size: 12px;
  padding: 12px 35px 12px 14px;
}

.admin-pdad .workspace-item:hover .accordion-button {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.admin-pdad .workspace-item:hover .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.pdad-country-body input,
.pdad-country-body textarea,
.pdad-relatdoc-body input,
.pdad-relatdoc-body textarea {
  font-size: 12px;
}

.pdad-relatdoc-body {
  border-bottom: none;
  padding-bottom: 5px;
}

.pdad-content {
  color: #0033a0;
}

/*/ PDAD Sharing END /*/

/* Main Wrapper/body Section Ends */

/* Footer Section Starts */

footer {
  width: 100%;
  /* height: 300px; */
  background: #0033a0;
  padding: 30px;
  background-image: url(./assets/images/footer-bg-circle.svg),
    url(./assets/images/footer-bg-circle-small.svg);
  background-position-x: 105%, 20%;
  background-position-y: 100%, 180px;
  background-repeat: no-repeat;
}

footer .container .foot-wrapper {
  /* height: 100%; */
  /* padding: 0 90px; */
  margin-top: 40px;
}

footer .logo-brand,
footer .help,
footer .quick-links {
  padding: 0 40px;
  /* min-height: 130px; */
}

.foot-link-title {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}

footer ul {
  padding: 0;
}

footer ul li {
  list-style: none;
  text-transform: capitalize;
}

footer ul li a {
  color: #ddd;
  font-size: 13px;
  text-decoration: none;
}

footer ul li a:hover {
  color: #fff;
}

/* Footer Section Ends */

/* ScrollBar */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 3px;
}

.admin-main-body::-webkit-scrollbar,
.about-policy-desc-min::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e5e5e5;
}

html {
  scrollbar-color: #e5e5e5 transparent;
}

.sidewrapper::-webkit-scrollbar {
  width: 5px;
}


.peer-body .cop-col {
  min-width: 292px;
}

/* Media Queries */

@media screen and (max-width: 1920px) {
  .develop-banner .container {
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .develop-banner .container {
    width: 93%;
  }

  .director-gen {
    height: 195px;
  }
}

@media screen and (max-width: 1366px) {
  .develop-banner .container {
    width: 85%;
  }

  header {
    height: 75px;
  }

  .slider-wrapper {
    width: 100%;
  }

  .slider-wrapper .carousel-item {
    object-fit: cover;
  }

  .event-writup,
  .news-writup {
    font-size: 13px;
    width: 170px;
  }

  

  .migration-repo,
  .director-gen {
    padding: 25px 20px;
  }

  .director-gen {
    height: 195px;
  }

  .director-gen p a {
    font-size: 22px;
  }

  .sec-link-item {
    padding: 10px 15px;
    font-size: 12px;
  }

  .sec-input-item {
    /*margin-bottom: 20px !important;*/
  }

  .sec-input-item span {
    font-size: 12px;
    padding: 10px 15px;
  }

  .slider-wrapper .carousel-item {
    height: 297px;
  }

  .banner-img {
    height: 470px;
  }

  .develop-banner {
    position: relative;
    height: 400px;
  }

  .feed-btn span {
    line-height: 19px;
  }

  .sec-link-2 .sec-link-item {
    margin-bottom: 5px;
    margin-right: 2px;
  }

  .feed-btn {
    padding: 12px 20px;
  }

  body {
    background-position: 221% -270px;
  }

  /* .admin footer {    
    height: 9%;
} */
  .admin header .brand {
    padding: 9px 9px;
  }

  .moderators-list-inner button {
    padding: 5px 20px;
  }

  .peer-body .cop-col {
    min-width: 248px;
    width: 248px;
  }
}

@media screen and (max-width: 1280px) {
  .develop-banner .container {
    width: 91%;
  }

  .banner-img {
    height: 450px;
  }

  .feed-btn span {
    line-height: 18px;
  }

  .develop-banner p {
    margin-top: 18px;
    margin-bottom: 33px !important;
    line-height: 48px;
  }

  .peer-body .cop-col {
    min-width: 248px;
    width: 248px;
  }
}

@media screen and (max-width: 1024px) {
  .feed-btn {
    padding: 12px 15px;
  }

  .k-notification-group {
    margin-top: 8% !important;
  }

  .news-event-wrapper {
    padding: 0;
  }

  .event-icon,
  .news-icon {
    padding: 2px !important;
  }

  .develop-banner p {
    font-size: 28px;
    line-height: 40px;
  }

  .develop-banner {
    height: 340px;
  }

  .banner-img {
    clip-path: polygon(0 0, 100% 0, 100% 76%, 0 95%);
    height: 338px;
  }

  .slider-wrapper {
    width: 100%;
    padding-left: 0;
  }

  .slider {
    padding: 0;
  }

  body {
    background-position: 1161% -290px;
  }

  .develop-banner .container {
    width: 95%;
    padding: 0;
  }

  .slider-wrapper .carousel-item {
    height: 257px;
  }

  .director-gen {
    height: 100%;
  }

  .migration-other-link p {
    margin: 0 4px !important;
  }

  .mod-list-inner {
    display: flex;
    flex-direction: row;
  }

  .m-moderators-list-inner {
    /* flex-direction: column; */
    display: flex;
    /* flex-direction: row; */
    width: 400px;
  }

  .l-moderators-list-inner {
    /*display: none;*/
  }

  .moderators-list-inner {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .moderators-list-inner button {
    border-color: #0033a0;
    color: #0033a0;
    margin-bottom: 0 !important;
  }

  .moderators-list-inner button:first-child {
    margin-right: 60px;
    width: 150px;
  }

  .member-stats {
    width: 40% !important;
  }

  .mod-list-inner .modeerator-member {
    margin-right: 30px;
  }

  .moderators-header-wrapper {
    padding: 8px 15px;
  
  }

  .moderators-content-inner {
    padding-left: 11px;
  }

  .moderators-tab-content {
    padding: 15px 0;
  }

  .moderator-title {
    text-align: left !important;
    margin-top: 8px;
  }

  .cop-col {
    width: 288px;
  }

  .toggle-menu {
    display: block;
  }

  .sidebar {
    position: fixed;
    left: -100%;
    transition: all 0.2s ease-in-out;
  }

  .showsidebar {
    left: 0;
    z-index: 10;
  }

  .showsidebar + .right-content {
    /* padding-left: 300px; */
    transition: all 0.3s ease-in-out;
  }

  .admin-card p {
    margin-bottom: 20px;
  }

  .admin-img-list {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .img-col {
    min-width: 220px;
    height: 119px;
    max-width: 250px;
  }

  .admin-date {
    width: 140px;
  }

  .know-train .knowledge-body-wrapper .cop-col {
    min-width: 203px;
    height: 155px;
    width: 203px;
  }
}

@media screen and (max-width: 768px) {
  .slider-wrapper {
    margin: 0 auto;
    max-width: 720px;
    padding-right: 0;
  }

  .slider-wrapper .carousel-inner {
    border-radius: 15px;
    height: 393px;
  }

  .k-notification-group {
    margin-top: 10% !important;
  }

  .policy-hub-about-sec,
  .news-event-wrapper,
  .news-wrapper {
    margin-top: 50px;
  }

  .director-gen {
    height: 250px;
  }

  .news-wrapper {
    margin-top: 0;
  }

  .develop-banner .container {
    width: 94%;
    max-width: 666px;
  }

  .develop-banner p {
    font-size: 28px;
    line-height: 40px;
  }

  .virtual-workspace .row,
  .pdad .row,
  .knowledge-sharing .row {
    flex-direction: column-reverse;
  }

  .virtual-workspace .container img,
  .pdad .container img,
  .knowledge-sharing .container img {
    border-top-left-radius: 20px;
    border-bottom-right-radius: 0px;
  }

  footer {
    height: 100%;
    background-position-x: 205%, 10%;
    background-position-y: 130%, 210px;
  }

  footer .container .foot-wrapper {
    margin-top: 20px;
  }

  .banner-img {
    clip-path: polygon(0px 0px, 100% 0px, 100% 56%, 0px 75%);
  }

  .develop-banner {
    height: 290px;
    width: 95%;
  }

  .feed-btn {
    float: none;
  }

  .feed-btn span {
    line-height: 19px;
    padding-left: 0px;
  }

  .director-gen {
    background-position-y: -50px;
  }

  .event-writup,
  .news-writup {
    font-size: 13px;
    width: 250px;
  }
  /* 
  .modeerator-member{
    flex-direction: column;
  } */

  .member-name {
    text-align: left;
    /* margin-top: 10px; */
    margin-left: 12px !important;
  }

  .moderators-footer-inner button {
    text-align: center;
    margin: auto;
    float: none !important;
    margin-bottom: 16px;
  }

  .moderators-footer-inner button:first-child {
    margin-top: 10px;
  }

  .moderators-footer-inner button:last-child {
    margin-bottom: 10px;
  }

  .moderators-content-inner nav.cop-tab-nav {
    /* overflow-x: auto; */
    height: auto;
  }

  .overflow-sm {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .content-header .row {
    height: auto;
    flex-direction: column-reverse;
  }

  .content-tab-table thead th {
    min-width: 130px;
  }

  .cop-col {
    width: 330px;
  }

  .moderators {
    padding-right: 13px !important;
  }

  .mod-list-inner {
    display: flex;
  }

  .m-moderators-list-inner {
    width: 320px;
  }

  .peer-body .cop-col {
    min-width: 143px;
    height: 120px;
    width: 143px;
  }
}

.p-10 {
  padding: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.py-30 {
  padding: 30px 0;
}

.p-30 {
  padding: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

#up-file {
  display: none;
}
#save-file {
  display: none;
}

.mr-10 {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.w100h30 {
  width: 100px;
  height: 30px;
}

.trim-text {
  display: block;
  width: 100px;
}

/*Added by dev team*/

.disabledDiv {
  background-color: #999;
  color: #fff;
}
.popup {
  border-radius: 15px;
}

.accordion-collapse {
  border: none;
}

.admin .sidebar .accordion-item .accordion-button {
  border: none;
}

app-admin-page ~ app-footer {
  display: none;
}

.disabledSpan {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

.dropdown-check-list .k-multiselect-wrap {
  height: 75px;
  max-height: 75px;
  overflow: scroll;
  padding-top: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  border-color: #ccc; 
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.authorize-list .accordion-item .accordion-button {
  border-radius: 6px;
  border-bottom: none;
  box-shadow: 0 13px 25px rgb(0 0 0 / 5%) !important;
}

.moderator-modal .modal-dialog .modal-body {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.k-notification-group {
  margin-top: 6%;
  position: fixed;
  z-index: 1048;
}
.k-notification {
  padding: 0.75rem;
}

.k-notification-content {
  padding-left: 5px;
  padding-right: 5px;
  z-index: 2;
}
.example-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 300px;
  height: 300px;
  border: 1px solid #000000;
}
.example-text {
  font-size: 1.5rem;
}
.k-button {
  margin-bottom: 20px;
}

.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #ff6757;
  z-index: 4;
  height: 150%;
}

.search-member .k-animation-container {
  width: 71%;
}

.member-fil-col button {
  font-size: 10px;
  padding: 5px 10px;
}
.member-fil-col {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

/* PDAD Start */

.pdad-wrapper .cop-landing .cop-header {
  /*background-color: #418fde;*/
}

.pdad-wrapper .cop-landing .work-table-th th label,
.pdad-wrapper .cop-landing .work-table-th th svg {
  /*color: #418fde;*/
}

.pdad-governance iframe,
.migration-governence iframe {
  width: 100%;
  border-radius: 15px;
  height: 750px;
}

.pdad-governance,
.good-policy,
.migration-governence,
.national-mig-policy,
.mig-policy-repo {
  margin: 30px 0;
  margin-bottom: 50px;
}

.good-policy .nav-tabs {
  border-bottom: 4px solid #e6ebf6;
  padding-bottom: 30px;
  height: 40px;
  width: 99%;
  cursor: pointer;
}

.good-policy .nav-tabs .nav-link {
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 25px 30px 35px 30px;
 
  font-size: 20px;*/
  color: #333333; 
  border-bottom: 1px;
  border-color:none;
}

.good-policy .nav-tabs .nav-link.active,
.good-policy .nav-tabs .nav-link:hover {
  background: transparent;
  border-color: #fff #fff #0032a0 #fff !important;
  border-bottom: 4px solid #0032a0;
  color: #0032a0;
  height: 39px;
}

.good-policy .tab-pane {
  background-color: #418fde;
  border-radius: 20px;
  overflow: hidden;
  /* max-height: 400px; */
  height: 100%;
}

.good-policy .tab-pane .carousel-inner {
  max-height: 400px;
  height: 100%;
}

.good-policy .tab-content {
  padding-top: 20px;
}

.good-policy .good-pract {
  height: auto !important;
}

.btn-good-practice {
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  color: #fff;
  background-color: #0033a0;
  border: 0px transparent;
  text-decoration: none;
}

.btn-good-practice:hover {
  color: #fff;
  background-color: #0033a0;
}

.pd-left-20 {
  padding-left: 20px;
}

.good-pract {
  padding: 40px 30px;
  height: 100%;
}

.good-pract .good-pract-title,
.submit-content-left .good-pract-title {
  font-size: 40px;
  font-weight: 100;
  color: #fff;
  line-height: 50px;
}

/* .good-doc-content {
  position: absolute;
  left: 70px;
  bottom: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px 35px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
} */
.good-doc-content {
   background-color: rgba(255, 255, 255, 0.1); 
   padding: 30px 35px;
   border-top-left-radius: 20px;
   border-bottom-left-radius: 20px;      
   max-height: 160px;
   overflow: auto;
 }

.good-doc-content p {
  color: #ffffff;
  font-size: 14px;
}

.pdad-tab .pdad-btn {
  border-color: #ffffff;
  color: #ffffff;
  max-width: 110px;
  padding: 6px 0;
}

.pdad-tab .pdad-btn:hover {
  background-color: #ffffff;
  color: #0033a0;
}

.submit-content-left {
  padding: 40px 30px;
  height: 100%;
}

.submit-content-right {
  padding: 40px 30px;
  
}

.template-content {
  background-color: #6dbfb9;
}

.submit-content-left .good-pract-title + p {
  color: #ffffff;
  font-size: 14px;
  opacity: 0.5;
}

.submit-content-list {
  width: 95%;
  height: 250px;
  overflow: auto;
}

.submit-content-list p {
  color: #ffffff;
  /* margin-bottom: 30px; */
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}

.submit-content-list p svg {
  margin-right: 5px;
}

.spn-attach-icon {
    margin-right: 4px;
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 4px 3px 4px 7px;
    vertical-align: middle;
    background: #7cc5c0;
}

.spn-dwn-icon  {
  float: right;
}

.spn-dwn-icon a, 
.spn-dwn-icon a:hover {
  color: #ffffff;
  text-decoration: none;
}

.btn-template-all {
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  color: #ffffff;
  background-color:#feb81d;
  border: 0px transparent;
  text-decoration: none;
  margin-right: 10px;
}

.btn-template-all:hover
{
  color: #ffffff;
  background-color:#feb81d;
}

.nmp {
  border: 1px solid transparent;
  border-radius: 20px;
}

.nmp-head {
  padding: 15px 15px;
  margin: 0;
  color: #ffffff;
  border-radius: 17px 17px 0px 0px;
  background-color: #2a72ba;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.nmp-map {
  width: 100%;
  height: 329px;
  border-radius: 0px 0px 17px 17px;
  background-color: #2a72ba;
  overflow: hidden;
}

.nmp-map iframe {
  width: 100%;
  height: 100%;
}

.nmp-relat-doc {
  width: 100%;
  height: 396px;
  border: 1px solid transparent;
  border-radius: 14px;
  background: #418fde;
  overflow: hidden;
}

.relat-doc-head {
  width: 100%;
  padding: 20px 40px;
}

.relat-doc-head p {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}

.relat-doc-body {
  width: 98%;
  height: 230px;
  overflow: auto;
  background: #418fde;
  margin-bottom: 4px;
  padding-right: 25px;
  padding-left: 40px;
}

.relat-doc-footer {
  padding: 20px 20px;
  width: 90%;
  height: 60px;
  border-radius: 12px;
  background: #FFF;
  margin: auto;
  margin-top: 20px;
}

.relat-doc-footer p {
  width: 720px;
  font-size: 14px;
  color: #999999;
  font-weight: 100;
  margin: 0;
  margin-right: 0px;
}

.doc-icon {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.10);
  padding: 7px;
}

.doc-icon p {
  font-size: 14px;
  color: #333333;
}

.relat-doc-body .row .col-lg-4 {
  margin-bottom: 20px;
}

.relat-doc-items {
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 10px 10px;
  background-color: #4c96e0;
  margin-bottom: 10px;
}

.poly-repo-wrap .accordion-button {
  background-color: #f4f4f4 !important;
  color: #418fde;
  border-left: 10px solid #418fde;
  font-size: 14px;
}

.poly-repo-wrap .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 10%) !important;
  border-color: #0033a0;
}

.poly-repo-wrap .accordion-button:hover {
  background-color: #fff !important;
  border-color: #0033a0;
}

.repo-count {
  font-size: 12px;
  font-weight: bold;
  background-color: #418fde;
  color: #fff;
  border-radius: 10px;
  padding: 4px 7px;
  margin-left: 15px;
}

.poly-repo-wrap .accordion-item {
  border: none;
  margin-bottom: 1px;
}

.poly-repo-wrap .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.poly-repo-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0;
}

.poly-repo-wrap .accordion-body {
  padding: 0 10px;
}

.poly-repo-wrap .accordion-body > div {
  border-left: 2px solid #0033a0;
  margin-bottom: 0;
  padding: 0px 15px;
}

.poly-repo-wrap .accordion-body > div p {
  font-size: 12px;
  color: #0033a0;
  margin-bottom: 0;
  padding: 20px 10px;
  border-bottom: 1px dashed #0033a0;
}

.poly-repo-wrap .accordion-body > div p:last-child {
  border: none;
}

.poly-repo-wrap {
  margin-bottom: 20px;
}

.mig-poly-title {
  color: #333333;
  font-size: 30px;
  font-weight: 100;
  margin-bottom: 10px;
}

.upload-content-doc {
  max-width: 48.2%;
}

.upload-content-doc + button {
  padding: 5px 11px;
  font-size: 10px;
  font-weight: bold;
}

.input-badge .badge {
  border: 1px solid #cccccc;
  background-color: #ffffff80;
  font-size: 12px;
  color: #333333;
  border-radius: 20px;
  padding: 7px 13px;
  font-weight: 400;
  margin-right: 10px;
  width: fit-content;
  word-wrap: break-word;
  text-align: left;
  white-space: break-spaces;
  display: flex;
  overflow-wrap: anywhere;
  margin-bottom: 5px;
}

.input-badge .badge svg {
  font-weight: 100;
  font-weight: 100;
  margin-left: 10px;
  color: #555;
}

.mgi-body iframe {
  min-height: 1000px;
  margin-bottom: 70px;
}

.gpa-head {
  background-color: #fcfcfc;
  padding: 30px;
}

.gpa-head-title {
  font-size: 30px;
  color: #333333;
  font-weight: lighter;
  margin-bottom: 0;
}

.gpa-body {
  margin-bottom: 70px;
}

.gpa-title {
  color: #333333;
  font-size: 20px;
  font-weight: bolder;
}

.foundation-accordion,
.keyareas-accordion {
  margin-bottom: 50px;
}

.foundation-accordion .accordion-item .accordion-button::after,
.keyareas-accordion .accordion-item .accordion-button::after {
  content: "\f078";
  background-image: none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: auto;
  height: auto;
  color: #0033a0;
}

.foundation-accordion .accordion-button:not(.collapsed)::after,
.keyareas-accordion .accordion-button:not(.collapsed)::after,
.foundation-accordion .accordion-button:hover::after,
.keyareas-accordion .accordion-button:hover::after {
  color: #fff !important;
}

.foundation-accordion .accordion-button:not(.collapsed),
.keyareas-accordion .accordion-button:not(.collapsed),
.foundation-accordion .accordion-button:hover,
.keyareas-accordion .accordion-button:hover {
  background-color: #0033a0;
  color: #fff !important;
}

.foundation-accordion .accordion-item .accordion-button,
.keyareas-accordion .accordion-item .accordion-button {
  /* font-size: 14px;
  color: #0033A0;
  font-weight: bold; */
  padding: 20px;
}

.foundation-accordion .accordion-item .accordion-button a,
.keyareas-accordion .accordion-item .accordion-button a {
  text-decoration: none;
  font-size: 14px;
  color: #0033a0;
  font-weight: bold;
  text-transform: uppercase;
}

.foundation-accordion .accordion-button:not(.collapsed) a,
.keyareas-accordion .accordion-button:not(.collapsed) a,
.foundation-accordion .accordion-button:hover a,
.keyareas-accordion .accordion-button:hover a {
  color: #fff !important;
}

.foundation-accordion .accordion-item:first-of-type .accordion-button,
.foundation-accordion .accordion-item:first-of-type,
.foundation-accordion .accordion-item:last-of-type .accordion-button.collapsed,
.foundation-accordion .accordion-item:last-of-type,
.keyareas-accordion .accordion-item:first-of-type .accordion-button,
.keyareas-accordion .accordion-item:first-of-type,
.keyareas-accordion .accordion-item:last-of-type .accordion-button.collapsed,
.keyareas-accordion .accordion-item:last-of-type {
  border-radius: 0;
}

.foundation-gmg {
  margin-bottom: 50px;
}

.foundation-accordion .accordion-item,
.keyareas-accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #0033a0;
}

.foundation-accordion .accordion-item:first-child,
.keyareas-accordion .accordion-item:first-child {
  border-top: 1px solid #0033a0;
}

.foundation-accordion .accordion-item .accordion-collapse,
.keyareas-accordion .accordion-item .accordion-collapse {
  border-left: 1px solid #0033a0;
  border-right: 1px solid #0033a0;
}

.foundation-accordion .accordion-item .accordion-button:not(.collapsed)::after,
.keyareas-accordion .accordion-item .accordion-button:not(.collapsed)::after {
  /* margin-right: 9px; */
  margin-top: -5px;
}

.foundation-accordion .accordion-item .accordion-body p,
.keyareas-accordion .accordion-item .accordion-body p {
  padding: 15px 10px;
  border-bottom: 1px dotted #cccccc;
  margin-bottom: 0;
}

.foundation-accordion .accordion-item .accordion-body p:last-child,
.keyareas-accordion .accordion-item .accordion-body p:last-child {
  border: none;
}
.foundation-accordion .accordion-item .accordion-body p a,
.keyareas-accordion .accordion-item .accordion-body p a {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  text-decoration: none;
}

.foundation-accordion .accordion-body,
.keyareas-accordion .accordion-body {
  padding: 0rem 1.25rem;
}

.emm-logo {
  margin-top: -5px;
}

.emm-logo img {
  width: 150px;
}

.pdc-select-result {
  background-color: #f5f6f8;
  width: 100%;
}

.policy-select-wrap {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #0033a0;
}

.policy-cycle-stage {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background-color: #fff;
  border: 15px solid #ffb81c;
  z-index: 6;
}

.policy-select-no {
  width: 370px;
  height: 370px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-49.5%, -50%);
  z-index: 0;
  overflow: hidden;
}

.policy-select-no svg {
  transition: all 0.5s ease-in-out;
}

.policy-select-no svg path {
  cursor: pointer;
}
/* 
.active[data-tab-target]{
  fill: #0033A0;
} */

/* 
.policy-col:nth-child(1){
  transform:translateX(-17px) rotate(-65deg);
}
.policy-col:nth-child(2){
  transform:translateX(-7px) rotate(-45deg);
} */

.pdad-wrapper .work-table-th th label {
  min-width: auto;
}

.pdad-wrapper .work-table-th th input {
  width: 89%;
}

.policy-process p {
  color: #333;
}

.policy-process-title {
  font-weight: 600;
}

.policy-process-list li {
  font-size: 14px;
  padding-bottom: 11px;
  color: #333;
}

.policy-process p.policy-process-sub {
  color: #999999;
}

.pdc-select-result {
  background-color: #f5f6f8;
  width: 100%;
}

[data-tab-content] {
  display: none;
}
.active[data-tab-content] {
  display: block;
}

.pdc-select-wrapper::before {
  content: "";
  width: 36px;
  height: 36px;
  background-color: #002375;
  position: absolute;
  transform: rotate(45deg);
  top: -18px;
  left: 185px;
}

.pdc-select-head {
  background-color: #002375;
  padding: 30px 50px;
}

.pdc-select-head p {
  color: #fff;
  font-size: 40px;
}

.pdc-select-body {
  background-color: #fff;
  width: 100%;
  padding: 30px 0;
  min-height: 456px;
}

.pdc-tab-head,
.pdc-tab-body {
  padding: 0 70px;
}

.pdc-tab-head {
  border-bottom: 1px solid #ddd;
}

.pdc-tab-head .nav-tabs {
  border-bottom: none;
}

.pdc-tab-head .nav-tabs .nav-link {
  background-color: transparent;
  border-color: transparent;
  border-bottom: 3px solid transparent;
  color: #999999;
  font-size: 16px;
  padding: 0 0 20px 0;
  margin-right: 65px;
}

.pdc-tab-head .nav-tabs .nav-link.active {
  border-bottom-color: #002375;
  color: #333333;
  font-weight: 600;
}

.pdc-tab-body {
  padding: 20px 70px;
}

.new-title {
  color: #333333;
  font-size: 25px;
  margin-bottom: 25px;
}

.pdc-list li {
  color: #333333;
  font-size: 16px;
  margin-bottom: 20px;
}

.stages-no p {
  position: absolute;
  color: #fff;
  font-size: 9px;
  max-width: 80px;
  text-align: center;
}

.stages-no p:nth-child(1) {
  /* top: 120px;
  right: 21px;
*/
  transform: translate(211px, -335px);
}

.stages-no p span {
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.policy-select-no svg g g text {
  fill: #fff;
  font-size: 18px;
  cursor: pointer;
}

.policy-select-no svg g g text tspan {
  font-size: 10px;
}

/* .policy-select-no svg g g:nth-child(1) text{
  transform: translate(1040px, 370px);
} */
.policy-select-no svg g g:nth-child(1) text {
  transform: translate(1055px, 445px) rotate(-101deg);
}
.policy-select-no svg g g:nth-child(2) text {
  transform: translate(1040px, 348px) rotate(-152deg);
}
.policy-select-no svg g g:nth-child(3) text {
  transform: translate(943px, 302px) rotate(159deg);
}
.policy-select-no svg g g:nth-child(4) text {
  transform: translate(846px, 349px) rotate(105deg);
}
.policy-select-no svg g g:nth-child(5) text {
  transform: translate(836px, 470px) rotate(59deg);
}
.policy-select-no svg g g:nth-child(6) text {
  transform: translate(897px, 540px);
}
.policy-select-no svg g g:nth-child(7) text {
  transform: translate(1010px, 523px) rotate(-51deg);
}

/* Admin Modal */
.admin-modal .modal-header {
  background-color: #fbfbfb;
}
.admin-modal .modal-body {
  background-color: #fff;
  border-radius: 0 0 calc(0.3rem - 1px) calc(0.3rem - 1px);
  padding: 20px 38px !important;
}

.admin-modal .modal-header .modal-title {
  font-size: 12px;
  font-weight: bolder;
}
/* Admin Modal */

.admin-pdad-country .workspace-item .work-img-title {
  flex: 1;
}

.admin-pdad-country .workspace-item > .accordion-header {
  min-height: 60px;
}
.admin-pdad .accordion-header .accordion-button {
  min-height: 60px;
}

.admin-pdad .workspace-item:hover .accordion-button .thematic-edit {
  display: block;
  margin: 5px 0;
}
/* PDAD End */

/* Knowledge Sharing Start */

.knowledge-head,
.knowledge-title,
.good-prac-body .result-work-table table thead tr {
  background-color: #5cb8b2;
}

.knowledge-title p {
  color: #fff;
}

.knowledge-body-wrapper {
  border: none;
}

.know-body .cop-title > p {
  padding: 0 9px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.good-prac-body .work-table-th th label,
.good-prac-body .work-table-th th svg,
.know-train .work-table-th th label,
.know-train .work-table-th th svg {
  color: #0032A0; /* #5cb8b2;*/
}

.good-prac-body .work-table-th th div button {
  padding: 7px 15px;
}

.good-sdg-col {
  width: 87px;
  height: 87px;
  overflow: hidden;
  margin-right: 5px;
  padding: 5px;
  border: 2px solid transparent;
}

.good-sdg-col img {
  width: 100%;
  height: 100%;
}

.good-sdg-col:hover,
.good-sdg-col.active {
  border-color: #0033a0;
}

/* .good-sdg-col:nth-child(1){background-color: #4C9F38;}
.good-sdg-col:nth-child(2){background-color: #A21942;}
.good-sdg-col:nth-child(3){background-color: #FD6925;}
.good-sdg-col:nth-child(4){background-color: #DD1367;}
.good-sdg-col:nth-child(5){background-color: #FD9D24;}
.good-sdg-col:nth-child(6){background-color: #00689D;}
.good-sdg-col:nth-child(7){background-color: #19486A;} */

.good-prac-body .result-work-table table thead tr th {
  color: #fff;
}

.good-prac-body
  .result-work-table
  .table-striped
  > tbody
  > tr:nth-of-type(odd) {
  background-color: #EDF6FF;/* rgba(92, 184, 178, 0.07);*/
}

.good-prac-body .result-work-table table td {
  vertical-align: top;
}

.good-prac-body .result-work-table table tbody tr:last-child,
.knowledge-footer .cop-footer-wrap .page-item.active .page-link.page {
  border-color: #CCCCCC; /* #5cb8b2;*/
}

.good-prac-body .work-table-th th label {
  min-width: 150px;
}

.good-prac-body
  .result-work-table
  .table
  > :not(:last-child)
  > :last-child
  > * {
  padding-left: 15px !important;
}

.good-prac-body .work-table-th th input {
  width: 100%;
}

.good-prac-body .work-table-td td .form-check input {
  height: 17px;
  width: 17px;
}

.know-next path,
.know-next-last path {
  fill: #5cb8b2;
}

.know-train .knowledge-body-wrapper .cop-col {
  /* background-color: rgba(92 184 178); */
  background-color: #fff;/* rgba(92, 184, 178, 1);*/
  /*border: 1px solid #0032A0;*/
}

/*
.know-train .knowledge-body-wrapper .cop-col:nth-child(2) {
  background-color: rgba(92, 184, 178, 0.95);
}
.know-train .knowledge-body-wrapper .cop-col:nth-child(3) {
  background-color: rgba(92, 184, 178, 0.9);
}
.know-train .knowledge-body-wrapper .cop-col:nth-child(4) {
  background-color: rgba(92, 184, 178, 0.85);
}
.know-train .knowledge-body-wrapper .cop-col:nth-child(5) {
  background-color: rgba(92, 184, 178, 0.8);
}
.know-train .knowledge-body-wrapper .cop-col:nth-child(6) {
  background-color: rgba(92, 184, 178, 0.75);
}
.know-train .knowledge-body-wrapper .cop-col:nth-child(7) {
  background-color: rgba(92, 184, 178, 0.7);
}
.know-train .knowledge-body-wrapper .cop-col:nth-child(8) {
  background-color: rgba(92, 184, 178, 0.65);
}
*/

.know-train .knowledge-body-wrapper .cop-col p {
  color: #fff;
  font-size: 14px;
  /*font-weight: 600;*/
  max-width: 80%;
  text-align: left; /*center;*/
  margin-bottom: 0;
}

.know-train .work-table-th th label {
  min-width: 90px;
}

.know-train .work-table-th th input {
  width: 90%;
  font-weight: 600;
}
.know-train .work-table-th th input::placeholder {
  font-weight: 100;
}

.learning-btn {
  font-size: 12px;
}

.learning-placeholder{
  padding: 1% 0% 0% 3%;
}

.resource-hover {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #0032A0; /* #5cb8b2;*/
  border-radius: 10px;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.know-resource .resource-hover {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: #fff;
  padding: 0 15px;
  text-align: center;
}

.peer-body .resource-hover {
    background-color: rgba(92,184,178,.9);
    padding: 0 15px;
    text-align: center;
}

.know-resource .resource-hover .visit-page {
  margin-bottom: 0;
}

.know-resource .cop-col img {
  height: 100%;
}

.know-resource .cop-col:hover .cop-title,
.peer-body .cop-col:hover .cop-title,
.peer-body .cop-col.active .cop-title {
  display: none !important;
}

.knowledge-body-wrapper .cop-col:hover .resource-hover,
.peer-body .cop-col:hover .resource-hover,
.peer-body .cop-col.active .resource-hover {
  opacity: 1;
}


.resource-hover svg,
.resource-hover p,
.resource-hover p a {
  color: inherit; /* #5cb8b2 !important;*/
}

.know-resource .resource-hover svg,
.know-resource .resource-hover p,
.know-resource .resource-hover p a,
.peer-body .resource-hover svg,
.peer-body .resource-hover p,
.peer-body .resource-hover p a {
  color: inherit; /* #fff !important;*/
}


.resource-hover p a {
  font-size: 12px;
  text-transform: uppercase;
  padding: 3px 30px;
  text-decoration: none;
  border-bottom: 1px solid #EDF6FF;/* #5cb8b2;*/
  margin: 5px 0 15px 0;
  display: block;
}

.know-resource .resource-hover p a {
  border-bottom: 1px solid #fff;
}

.peer-search {
  background-color: #f4f4f4;
  padding: 1px 0;
  margin-top: 20px;
}

.peer-search .find-work-table input {
  background-color: transparent;
}

.peer-inner-img {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.peer-inner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.peer-detail {
  background-color: #f4f4f4;
  padding: 50px 0;
}

.peer-title {
  font-size: 20px;
  font-weight: bold;
  color: #0033a0;
}

.peer-lhd-img {
  width: auto;
  height: 205px;
  overflow: hidden;
  border-radius: 10px;
}

.peer-lhd-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.peer-desc p {
  color: #666666;
  font-size: 18px;
  font-weight: 100;
}

.peer-collab {
  background-color: #fff;
  padding: 23px 17px;
  border-radius: 10px;
}

.yammer-img {
  width: 74px;
  height: 74px;
  overflow: hidden;
}

.lhd-img {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 10px #0000001a;
}

.yammer-img img,
.lhd-img img {
  width: 100%;
  height: 100%;
}

.lhd-title {
  color: #0033a0;
  font-size: 14px;
  font-weight: bolder;
}

.lhd-new-desc {
  color: #999999;
}

.lhd-admin-title {
  color: #333;
}

.lhd-admin-img-col {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
}

.lhd-admin-img-col img, .lhd-admin-img-col div {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.peer-slide-btn p {
  margin: 0 5px;
  cursor: pointer;
}

.peer-slide-btn p.prev {
  transform: rotate(180deg);
}

.peer-slide-btn .bullets {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #EDF6FF; /* #5cb8b2;*/
}

.peer-slide-btn .bullets.active {
  background-color: #EDF6FF; /* #5cb8b2;*/
}

.peer-member {
  background-color: #fff;
  padding: 23px 17px;
  border-radius: 10px;
}

.peer-mem-title, .lhd-admin-title {
  font-size: 14px;
  font-weight: bold;
  color: #0033a0;
}

.peer-mem-title + .badge {
  background-color: #f4f4f4 !important;
  border-radius: 15px;
  padding: 5px 10px;
  color: #999999 !important;
  font-size: 14px;
  font-weight: bold;
}

.peer-mem-list {
  max-height: 500px;
  overflow-y: auto;
}

.peer-mem-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.peer-mem-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.peer-name {
  color: #333333;
  font-size: 14px;
}

.peer-designation {
  color: #999;
  font-size: 12px;
  margin-bottom: 0;
}

.peer-mem-col {
  margin-bottom: 20px;
  align-items: center;
}

.peer-mem-col:last-child {
  margin-bottom: 0;
}

.know-reso-mod .modal-body select {
  max-width: 100%;
  border-radius: 6px;
  border: 1px solid #ced4da;
}

.type-question {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
}

.type-question textarea {
  height: 80px !important;
  overflow: auto !important;
}

.new-btn {
  color: #ffffff;
  font-size: 13px;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 10px;
  background: #0032A0;
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  gap: 10px;
}

.new-btn:hover {
  background-color: #0032A0;
  border-color: #0032A0;
  color: #ffffff;
}

.type-question .new-btn {
  font-size: 12px;
}

.lhd-question {
  padding: 20px 10px;
  background-color: #fff;
  border-radius: 10px;
}

.question-head .form-check-label {
  color: #0033a0;
  font-size: 12px;
}

.question-title {
  color: #0033a0;
  font-size: 14px;
  font-weight: bold;
}

.my-question-check {
  width: 126px;
}

.question-search {
  width: 80%;
}

.que-sear-fill input {
  background-color: #fcfcfc;
  border-radius: 20px;
  border: none;
  height: 30px;
  padding: 0 40px 0 15px;
}

.que-sear-fill svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #0033a0;
  font-size: 10px;
  cursor: pointer;
}

.question-list-col {
  border: 1px solid #cccccc;
  /* padding: 20px; */
  border-radius: 10px;
  background-color: #f4f4f4;
}

.main-que-head,
.main-que-body {
  padding: 20px;
}

.main-que-body {
  background-color: #fff;
  border-radius: 9px;
}

.yammer-btn > button {
  border: none;
  background-color: #fff;
  color: #0078d4;
  font-size: 12px;
  padding: 6px 9px;
  border-radius: 15px;
}

.yammer-btn > button > img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.que-prof-name {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.que-prof-name span {
  color: #999999;
  font-size: 12px;
  font-weight: 500;
}

.que-prof-name span svg {
  color: #0033a0;
  font-size: 12px;
}

.que-prof-designation {
  font-size: 12px;
  color: #5cb8b2;
}

.main-ques-prof {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.qustion-profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.qustion-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.question-main-list {
  max-height: 952px;
  overflow: auto;
  padding-right: 10px;
}

.my-question .question-main-list {
  max-height: 100%;
  padding-right: 0px;
}

.my-que-head {
  width: 100%;
  background-color: #0033a0;
  border-radius: 10px;
  padding: 20px;
}

.my-que-head p {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.my-que-head + .lhd-question {
  margin-top: -20px;
}

.how-work-wrap {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 30px;
  width: 900px;
  position: absolute;
  z-index: 10;
  display: none;
  margin-top: 10px;
}

.how-work-wrap .how-title {
  color: #666666;
  font-size: 18px;
  font-weight: bold;
}
.how-work-wrap .how-desc {
  color: #666666;
  font-size: 14px;
}

.how-work:hover + .how-work-wrap {
  display: block;
}

.know-train
  .knowledge-body-wrapper.peer-body
  .cop-col
  .resource-hover
  .resource-dev,
.know-train
  .knowledge-body-wrapper.peer-body
  .cop-col.active
  .resource-hover
  .resource-dev {
  font-size: 20px;
  font-weight: 600;
}

.know-train .knowledge-body-wrapper.peer-body .cop-col.active {
  cursor: auto;
}

.know-train .knowledge-body-wrapper.peer-body .cop-col.active img {
  transform: scale(1);
}

/* Knowledge Sharing End */

div.country-fil-col:hover {
  background-color: lightblue;
}

.k-dialog-titlebar {
  background-color: #0033a0;
}
.k-button-primary, .k-button.k-primary{
    border-color: #0033a0;
    background-color: #0033a0;
}


/* PDAD Sharing END */

/* Anallytics */

.date-filter .col-auto {
  display: flex;
  align-items: center;
  position: relative;
}

.date-filter .col-auto label {
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 14px;
  color: #333333;
}

.date-filter .col-auto input {
  border-radius: 20px;
  padding: 4px 28px 4px 8px;
  font-size: 12px;
  color: #333;
  width: 120px;
  border-color: #cccccc;
}

.date-filter .col-auto svg {
  position: absolute;
  right: 23px;
  color: #d9e0f1;
}

.platform-col {
  background-color: #fff;
  min-height: 100px;
  border-radius: 6px;
  padding: 20px;
  /* cursor: pointer; */
}

.platform-col p {
  margin-bottom: 0;
}

.platform-col .platform-title {
  font-size: 13px;
  color: #333333;
  padding-right: 20px;
}
.platform-col .platform-no {
  font-size: 40px;
  color: #0033a0;
  font-weight: 100;
}

.Analytics-platform-usage > .row {
  row-gap: 15px;
}

.mem-search > div input {
  border-radius: 0;
  border: 2px solid #cccccc;
  padding: 12px;
  padding-right: 40px;
  font-size: 12px;
  color: #333;
}

.mem-search > div svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.search-note {
  font-size: 12px;
  color: #999999;
  margin-top: 80px;
}

.report-other > ul li {
  list-style: none;
  padding: 17px;
  border-top: 1px solid #fff;
}

.report-other > ul li:last-child {
  border-bottom: 1px solid #fff;
}

.report-other > ul li .form-check input {
  border: 1px solid #999999;
  background-color: #fff;
  border-radius: 3px;
}

.report-other > ul li .form-check .form-check-input:checked {
  background-color: #0033a0;
  border: none;
}

.report-other > ul li .form-check label {
  font-size: 14px;
  color: #333333;
}
/* Anallytics END*/
/* Thematic Area START*/
.four_sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.four_sections .sections_add_btn a.add-link {
  display: flex;
  flex-wrap: wrap;
  width: 74px;
  align-items: center;
  justify-content: space-around;
  margin-left: 0;
}
.four_sections .four_sections_inner {
  width: calc(100% - 94px);
  border: 1px solid #adb6bf;
  padding: 30px 20px 10px 20px;
  border-radius: 6px;
  position: relative;
}
.workspace-form .four_sections_inner .form-label {
top: 0;
font-weight: bolder;
color: #000;
z-index: 0;
}
/* Thematic Area END*/

.sec-input-item1 
{
    padding: 0px;/*22px 0px 0px 57px;*/
    border-radius: 6px;
    font-size: 2px;
    /*border: 1px solid #ced4da;
    width: 30%;
    margin-left: 190px;
    border-radius: 20px;*/
}
.thamaticarea
{
font-size: 12px;
height: 12px;
}
.sec-input-item span {
  padding: 15px 20px;
  background: #fff;
  border: none;
  height: 36px;
}
.thamaticarealevelsize
{
  font-size: 12px;
  font-weight: 700;

}

.carousel-control-next, .carousel-control-prev {
  opacity: 40 !important;
}

.page-header-title{
    font-size: xx-large;
    font-weight: 700;
    color: #0032a0;
}

.page-header-bottom-border{
  border-bottom: 2px solid #ccc;
}

.k-pager-wrap{
  border-color: #0032a0;
    border-radius: 10px;
  color: #424242;
  background-color: #fff;
}

.k-pager-numbers .k-link{
  color: #000;
}

.k-pager-numbers .k-link:hover{
  color: #000;
  background-color: #efefef;
}

.k-pager-numbers .k-link.k-state-selected{
  color: #0032a0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.k-dropdown .k-dropdown-wrap{
  background-color: #fff;
}

.k-list .k-item.k-state-selected{
  color: #0032a0;
  background-color: #fff;

}

.k-list .k-item.k-state-selected:hover{
  color: #0032a0;
  background-color: #ccc;

}

.slide-show-class .k-scrollview-prev, 
.slide-show-class .k-scrollview-next,
.slide-show-class .k-scrollview-nav-wrap {
  z-index: 2;
}

.slide-show-class .k-scrollview-wrap .k-scrollview > li, .k-scrollview .k-scrollview-wrap > li img {
    height: 100% !important;
    width: 100% !important;
    display: grid !important;
}

